import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../templates/layout'
import { ArrowLeft } from '../components/icons/index'
import { Col, Grid, Row } from '../components/grid'
import { SEO, ProjectListItem as Project, Heading } from '../components/index'

const Speaking = ({ data }) => {
  const { edges: results } = data.allMdx
  return (
    <Layout>
      <SEO title={`Public Speaking`} />
      <Grid fluid xsTop="0" mdTop="6rem">
        <Row middle="xs" xsBottom="2rem" lgBottom="5rem">
          <Col xs={12} xsOffset={0} md={8} mdOffset={2}>
            <Row middle="xs">
              <Link to="/" style={{ marginRight: '2rem', color: '#F59CC5' }}>
                <ArrowLeft fill="#F59CC5" top="0.4rem" />
              </Link>
              <Heading level="1" size="h1" color="#F59CC5" accent={true} align="left">
                Speaking
              </Heading>
            </Row>
          </Col>
        </Row>
        <div>
          {results.map(({ node: project }, index) => (
            <Project data={project} key={index} index={index} />
          ))}
        </div>
      </Grid>
    </Layout>
  )
}
export const pageQuery = graphql`
  query allSpeaking {
    allMdx(filter: { fileAbsolutePath: { regex: "/speaking/" }, frontmatter: { published: { eq: true } } }) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            headline
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default Speaking
